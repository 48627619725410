var initpage = '';

var dvMain = function() {
    
    var initPages = function(){
        switch(initpage){
            default:
            break;
            case 'indexindex':
                dvGui.initFeature();
            break;
            case 'indexdetail':
                dvGui.initFeature();
            break;
            case 'alumniindex':
                dvGui.initAlumni();
            break;
            case 'alumnidetail':
                dvGui.initSlider();
            break;
            case 'agendaindex':
                dvGui.initAgenda();
            break;
            case 'agendapastevents':
                dvGui.initAgenda();
            break;
            case 'newsindex':
                dvGui.initNews();
            break;
            case 'programindex':
                dvGui.initProgram();
            break;
            case 'serviceindex':
                dvGui.initService();
                dvGui.initJobs();
            break;
            case 'benefitsindex':
                dvGui.initService();
                dvGui.initJobs();
            break;
            case 'benefits2index':
                dvGui.initService();
                dvGui.initJobs();
                dvGui.initBenefits();
            break;
            case 'profileindex':
            case 'profileeditcontact':
            case 'profileeditsettings':
            case 'profileeditprofile':
                dvForms.init();
            break;
            case 'metaregistration':
                dvForms.init();
            break;

            case 'agendaentry':
                dvForms.init();
            break;
            case 'jobsindex':
                dvGui.initJobs();
            break;
            case 'jobsentry':
                dvForms.init();
            break;
            case 'aboutindex':
                dvGui.initAbout();
            break;
            case 'prixindex':
            case 'prixinfo':
                dvGui.initPrix();
            break;
            case 'indexresetpasswd':
                dvGui.openMeta('resetpassword',hash);
            break;
            case 'storyindex':
                dvGui.initStory();
            break;
            case 'storydetail':
                dvGui.initSlider();
            break;
            case 'shopindex':
                dvGui.initShop();
                dvForms.init();
                break;
        }
    }

    
    /********* PUBLIC *********************************************************/
    return {
        init: function(ipg) {
            console.log('netzhdk v3.1: ' + ipg);
            initpage = ipg;
            
            if(ipg){
                dvGui.init();
                initPages();
            }
        }
    }
}();