var dvGui = function() {
    var resize_timeout = null;
    var scroll_timeout = null;
    var search_timeout = null;
    
    var initResize = function(){
        $( window ).resize(function() {
            window.clearTimeout(resize_timeout);
            resize_timeout = window.setTimeout(function(){
                initHeader();
            }, 400);
        });
    }
    var initMenu = function() {
        $('.menu').each(function() {
            if(!$(this).hasClass('has-function')){
                $(this).click(function(){
                    var body = $('body');
                    if(body.hasClass('nav-open')){
                        body.removeClass('nav-open');
                    } else {
                        body.addClass('nav-open');
                    }
                });
                $(this).addClass('has-function');
            }
        });
    }
    var initHeader = function() {
        var body = $('body');
        if(body.hasClass('home') || body.hasClass('alumnidetail') || body.hasClass('storydetail') || body.hasClass('featuredetail')){
            $('main').css('padding-top',0);
        } else {
            var scrolltop = $('html, body').scrollTop();
            var top = $('header').outerHeight();
            $('main').css('padding-top',top);
            if(scrolltop < 50){
                $('html, body').animate({scrollTop:0},500);
            }
            /*
            if(body.hasClass('agenda')){
                var full = $( window ).height();
                $('#agenda-container').css('height',(full-top-40) + 'px');
                console.log('agenda-container full: ' + full + ' top: ' + top);
            }

             */
        }
        $('.search-input').focus();
        
    }
    var initFeature = function() {
        console.log('init Feature');
        var feature = $('#feature-content');
        if(!feature.hasClass('has-function')){
            feature.click(function(){
                $('body').toggleClass('feature-read fixedheader');
                window.setTimeout(function(){
                    initSlider();
                    feature.find('a').on('click', function(event){
                        console.log('inner');
                        event.stopPropagation();
                    });

                },500);

            });
            feature.addClass('has-function');
        };
    }
    var initSlider = function() {
        $('.inline-slider .slider').bxSlider({
            /*auto: true,*/
            stopAutoOnClick: true,
            pager: ($(".inline-slider .slide").length > 1) ? true: false,
            /*adaptiveHeight:true,*/
            captions: true
        });
    }
    var initCalendar = function() {
        var cal = $('#calendar');
        if(!$(cal).hasClass('has-function')){
            var opts = {
                inline: true,
                firstDay: 1,
                showOtherMonths: true,
                dateFormat: 'yy-mm-dd',
                onSelect: function(date, datepicker) {
                    var day = $(this).val();
                    var title = $('#events_day .date');
                    var target = $('#events_day .inner');
                    target.html('<div class=\"loader\"><img src=\"/dist/loader.gif\"></div>');
                    
                    $.ajax({
                        type: "POST",
                        url: '/agenda/list',
                        data: '&day=' + day,
                        success: function(content) {
                            target.find('.loader').remove();
                            var json_data = $.parseJSON(content);
                            $.each(json_data['items'], function(i, item) {
                                var row = dvf_template(json_data['template'], item);
                                target.append(row);
                            });
                            title.html(json_data['title']);
                            initAgenda();
                        }
                    });
                }
            }
            
            $(cal).datepicker(opts);
            $(cal).addClass('has-function');
        }
    }
    var initMeta = function() {
            $('.open-meta').each(function(){
            if(!$(this).hasClass('hasopenmeta')){
                $(this).unbind('click');
                $(this).addClass('hasopenmeta');
                var metaview = $(this).attr('data-view');
                var var1 = $(this).attr('data-var1');
                var var2 = $(this).attr('data-var2');
                var metacont = $(this).attr('data-cont');
                $(this).click(function(){
                    openMeta(metaview,var1,var2,metacont); 
                })
            }
            $('.close-meta').each(function(){
                if(!$(this).hasClass('has-close')){
                    $(this).click(function(){
                        hideOverlay();
                    });
                    $(this).addClass('has-close');
                }
            });
        });
        $('.submitmeta').each(function(){
            if(!$(this).hasClass('hassubmitmeta')){
                $(this).unbind('click');
                $(this).addClass('hassubmitmeta');
                var metaview = $(this).attr('data-view');
                var metacont = $(this).attr('data-cont');
                $(this).click(function(){
                    submitMeta(metaview,metacont);
                })
            }
        });
        $('.submitmeta-enter').each(function(){
            if(!$(this).hasClass('hassubmitmeta-enter')){
                $(this).unbind('keyup');
                $(this).addClass('hassubmitmeta-enter');
                var metaview = $(this).attr('data-view');
                $(this).keyup(function(e){ 
                    var code = e.which; // recommended to use e.which, it's normalized across browsers
                    if(code==13)e.preventDefault();
                    if(code==32||code==13||code==188||code==186){
                        submitMeta(metaview);
                    } 
                });
            }
        });
    }
    var openMeta = function(metaview,var1,var2,metacont){
        if(!var1) var1 = '';
        if(!var2) var2 = '';
        if(!metacont) metacont = 'meta';
        showOverlay();
        $('#overlay_inner').html('<div class=\"loader\"><img src=\"/dist/loader.gif\"></div>');
        
        $.ajax({
            type: "POST",
            url: '/' + metacont + '/' + metaview,
            data: '&var1=' + var1 + '&var2=' + var2,
            success: function(content) {
                $('#overlay_inner').html(content).promise().done(function(){
                    initMeta();
                });
            }
        });
    }
    var submitMeta = function(metaview,metacont){
        if(!metacont) metacont = 'meta';
        var serialized = $('#' + metaview + 'form').serialize();
        $('#overlay_inner').html('<div class=\"loader\"><img src=\"/dist/loader.gif\"></div>');
        $.ajax({
            type: 'POST',
            url: '/' + metacont + '/' + metaview,
            data: serialized,
            success: function(content) {
                if(content == 'META OK'){
                    location.reload(true);
                } else {
                    $('#overlay_inner').html(content).promise().done(function(){
                        initMeta();
                    });
                }
            }
        });
    }
    var showOverlay = function(){
        $('#overlay').fadeTo('fast', 1);
        /*$('#overlay').height($(document).height());*/
    }
    var hideOverlay = function(){
        $('#overlay').fadeOut('fast');
    }
    
    var initLayer = function(){
        $('.open-layer').each(function(){
            if(!$(this).hasClass('has-layer')){
                $(this).on('click', function(event){
                    if ($(event.target).hasClass('podcast')) return; 
                    $('body').addClass('no-overflow');
                    showOverlay();
                    $('#overlay_inner').html('<div class=\"loader\"><img src=\"/dist/loader.gif\"></div>');

                    var url = $(this).find('.target-link').attr('href');
                    if(!url){
                        var url = $(this).attr('data-url');
                    }

                    $.ajax({
                        type: "POST",
                        url: url,
                        data: '&async=1',
                        success: function(content) {
                            $('#overlay_inner').html(content).promise().done(function(){
                                initSlider();
                                initLayer();
                                initFeature();
                            });
                        }
                    });
                    return false
                });
                $(this).addClass('has-layer')
            }
        })
        $('.close-layer').each(function(){
            if(!$(this).hasClass('has-close')){
                $(this).click(function(){
                    $('body').removeClass('no-overflow');
                    hideOverlay();
                });
                $(this).addClass('has-close');
            }
        });
        /*
        $('.podcast').each(function(){
            if(!$(this).hasClass('has-return')){
                $(this).click(function(){
                    return false;
                });
                $(this).addClass('has-return');
            }
        });*/
    }
    
    var initScrollto = function(){
        $('.scrollto').each(function(){
            if(!$(this).hasClass('has-scrollto')){
                $(this).on('click', function(){
                    var element = $(this).attr('data-element');
                    var top = $('#' + element).offset().top - $('header').height() + 1;
                    /*console.log('==> ' + element + ' --> ' + top);*/
                    $('html, body').animate({
                        scrollTop: top
                    }, 500);

                });
                $(this).addClass('has-scrollto')
            }
        })
    }
        
    var initAgenda = function(){
        $('.load-prev').each(function(){
            if(!$(this).hasClass('has-loading')){
                $(this).on('click', function(){
                    loadAgenda(1); 
                });
                $(this).addClass('has-loading');
            }
        });
        $('.load-next').each(function(){
            if(!$(this).hasClass('has-loading')){
                $(this).on('click', function(){
                    loadAgenda(0); 
                });
                $(this).addClass('has-loading');
            }
        });
        if(!$('#events .inner').hasClass('is-init')){
            $('#events .inner').scrollTop(0);
            var top = $('#events .inner .current').offset().top - $('header').height() + 1;
            $('#events .inner').scrollTop(top);
            $('#events .inner').addClass('is-init');
        }

        
        initLayer();
    }
    
    var loadAgenda = function(prev){
        if(prev > 0){
            var month = $('#events').attr('data-firstmonth');
            window.clearTimeout(scroll_timeout);
            $.ajax({
                type: 'POST',
                url: '/agenda/list',
                data: '&month=' + month + '&show=prev',
                success: function(content) {
                    var json_data = $.parseJSON(content);
                    
                    $('#events').attr('data-firstmonth',json_data['firstmonth']);
                    
                    $.each(json_data['items'], function(i, item) {
                        var row = dvf_template(json_data['template'], item);
                        $('#agendaresults').prepend(row);
                    });

                    var top = $('#events .inner h1').eq(1).offset().top - $('header').height() - 80;
                    $('#events .inner').scrollTop(top);
                    
                    initAgenda();                    
                }
            });        
        } else {
            var month = $('#events').attr('data-lastmonth');
            window.clearTimeout(scroll_timeout);
            $.ajax({
                type: 'POST',
                url: '/agenda/list',
                data: '&month=' + month + '&show=next',
                success: function(content) {
                    var json_data = $.parseJSON(content);
                    
                    $('#events').attr('data-lastmonth',json_data['nextmonth']);
                    
                    $.each(json_data['items'], function(i, item) {
                        var row = dvf_template(json_data['template'], item);
                        $('#agendaresults').append(row);
                    });
                    
                    initAgenda();                    
                }
            });        
        }
    }
    
    var initSearch = function(){
        $('.search-input').each(function(){
            if(!$(this).hasClass('has-function')){
                $(this).addClass('has-function');
                $(this).on('keyup', function(){
                    var that = $(this);
                    window.clearTimeout(search_timeout);
                    search_timeout = window.setTimeout(function(){
                        var target = $('#' + $(that).attr('data-target'));
                        var type = $(that).attr('data-type');
                        var url = '/' + type + '/list';
                        var src = $(that).val();
                        target.html('<div class=\"loader\"><img src=\"/dist/loader.gif\"></div>');
                        
                        $.ajax({
                            type: 'POST',
                            url: url,
                            data: '&src=' + src + '&offs=0&dvForm_subm=1',
                            success: function(content) {
                                target.html('')
                                var json_data = $.parseJSON(content);
                                $.each(json_data['items'], function(i, item) {
                                    var row = dvf_template(json_data['template'], item);
                                    target.append(row);
                                });
                                switch(type){
                                    case 'alumni':
                                        $('.more').attr('data-offset',100);
                                        initLayer();
                                    break;
                                    case 'agenda':
                                        $('#events .inner').scrollTop(0);
                                        var top = $('#events .inner .current').offset().top - $('header').height() + 1;
                                        $('#events .inner').scrollTop(top);
                                        initAgenda();
                                    break;
                                    case 'jobs':
                                        initLayer();
                                    break;
                                    case 'program':
                                        initLayer();
                                    break;
                                }
                            }
                        });        
                    },300);
                });
            }
        })
    }    
    
    var initFilters = function(){
        $('nav.filters .filter, nav .filter').each(function(){
            if(!$(this).hasClass('has-filter')){
                $(this).addClass('has-filter');
                $(this).click(function(){
                    var that = $(this);
                    var type = $(that).attr('data-type');
                    var filt = $(that).attr('data-filter');
                    var target = $('#' + $(that).attr('data-target'));
                    var url = '/' + type + '/list';
                    
                    $('nav.filters .filter, nav .filter').removeClass('selected');
                    $(that).addClass('selected');

                    target.html('<div class=\"loader\"><img src=\"/dist/loader.gif\"></div>');

                    $.ajax({
                        type: 'POST',
                        url: url,
                        data: '&filt=' + filt + '&offs=0&dvForm_subm=1',
                        success: function(content) {
                            target.html('')
                            var json_data = $.parseJSON(content);
                            $.each(json_data['items'], function(i, item) {
                                var row = dvf_template(json_data['template'], item);
                                target.append(row);
                            });
                            switch(type){
                                case 'alumni':
                                    $('.more').attr('data-offset',100);
                                    initLayer();
                                break;
                                case 'agenda':
                                    $('#events .inner').scrollTop(0);
                                    var top = $('#events .inner .current').offset().top - $('header').height() + 1;
                                    $('#events .inner').scrollTop(top);
                                    initAgenda();
                                break;
                                case 'program':
                                    $('.more').attr('data-offset',100);
                                    initLayer();
                                break;
                            }
                        }
                    });        
                });
            }
        });
    }
    var initMore = function(){
        $('.load-more').each(function(){
            if(!$(this).hasClass('has-function')){
                $(this).addClass('has-function');
                $(this).on('click', function(){
                    var that = $(this);
                    var offs = $(this).parent().attr('data-offset');
                    var target = $('#' + $(that).attr('data-target'));
                    var url = '/' + $(that).attr('data-target') + '/list';
                    target.append('<div class=\"loader\"><img src=\"/dist/loader.gif\"></div>');
                        
                    $.ajax({
                        type: 'POST',
                        url: url,
                        data: '&offs=' + offs + '&dvForm_subm=1',
                        success: function(content) {
                            target.find('.loader').remove();
                            var json_data = $.parseJSON(content);
                            $.each(json_data['items'], function(i, item) {
                                var row = dvf_template(json_data['template'], item);
                                target.append(row);
                            });
                            $(that).parent().attr('data-offset', parseInt(offs)+100);
                            initLayer();
                        }
                    });        
                });
            }
        })
    }
    
    var initContext = function(){
        $('nav.context .open-search').each(function(){
            if(!$(this).hasClass('has-function')){
                $(this).addClass('has-function');
                $(this).click(function(){
                    if($('nav.search').hasClass('open')){
                        $('nav.search').removeClass('open');
                        //$('.search-input').val('').keyup();
                    } else {
                        $('nav.search').addClass('open');
                        $('nav.filters').removeClass('open');
                        $('nav.filters .filter').removeClass('selected');
                        $('nav.filters .filter:first').click();

                    }
                    //$('nav.search').toggleClass('open');
                    window.setTimeout(function(){
                        initHeader();
                    },100);
                });
            }
        });
        $('nav.context .open-filters').each(function(){
            if(!$(this).hasClass('has-function')){
                $(this).addClass('has-function');
                $(this).click(function(){
                    //$('nav.filters').toggleClass('open');
                    if($('nav.filters').hasClass('open')){
                        $('nav.filters').removeClass('open');
                        //$('nav.filters .filter').removeClass('selected');
                        //$('nav.filters .filter:first').click();
                    } else {
                        $('nav.filters').addClass('open');
                        $('nav.search').removeClass('open');
                        $('.search-input').val('').keyup();
                    }

                    window.setTimeout(function(){
                        initHeader();
                    },100);
                });
            }
        });
        $('nav.context .showhide-info').each(function(){
            if(!$(this).hasClass('has-infofunction')){
                $(this).addClass('has-infofunction');
                $(this).click(function(){
                    if($(this).attr('data-filter') == 1){
                        $('#info').show(0);
                    } else {
                        $('#info').hide(0);
                    }
                });
            }
        });
        $('nav.context .program-filter').each(function(){
            if(!$(this).hasClass('has-infofunction')){
                $(this).addClass('has-infofunction');
                $(this).click(function(){
                    if($(this).attr('data-filter') == 'current'){
                        console.log('current');
                    } else if($(this).attr('data-filter') == 'past'){
                        console.log('past');
                    }
                });
            }
        });
    }
    

    

    


    /********* PUBLIC *********************************************************/
    return {
        init: function() {
            initHeader();
            initMenu();
            initResize();
            initMeta();
        },
        initAlumni: function() {
            initContext();
            initSearch();
            initFilters();
            initMore();
            initLayer()
        },
        initPrix: function() {
            initLayer()
            initContext();
        },
        initAgenda: function() {
            initContext();
            initSearch();
            initFilters();
            initMore();
            initLayer()
        },
        initNews: function() {
            initContext();
            initLayer()
            initMore();
            /*initSearch();*/
        },
        initBenefits: function() {
            initContext();
            initLayer()
            initMore();
            /*initSearch();*/
        },
        initProgram: function() {
            initContext();
            initSearch();
            initFilters();
            initMore();
            initLayer()
        },
        initJobs: function() {
            initContext();
            initLayer()
            initSearch();
        },
        initAbout: function() {
            initScrollto();
        },
        initService: function() {
            initScrollto();
            initLayer();
            initSlider();
        },
        initStory: function() {
            initScrollto();
            initLayer();
            initSlider();
        },
        openMeta: function(metaview,var1,var2,metacont) {
            openMeta(metaview,var1,var2,metacont);
        },
        initFeature: function() {
            initFeature();
        },
        initSlider: function() {
            initSlider();
        },
        initFilters: function() {
            initFilters();
        },
        initContext: function() {
            initContext();
        },
        initShop: function() {
            console.log('initShop');
            initContext();
            console.log('scrolling to form');

            var element = $('#shopformanchor');
            if (element.length) {
                var top = $('#' + element.attr('id')).offset().top - $('header').height() + 1;
                $('html, body').animate({
                    scrollTop: top
                }, 500);
            }



            $('#shopform').submit(function(e) {
                e.preventDefault();
                console.log('Form submitted');
                var hasQuantity = false;
                $('.shop-quantity-input').each(function() {
                    var value = parseInt($(this).val(), 10);
                    if (value > 0) {
                        hasQuantity = true;
                        return false;
                    }
                });

                if (!hasQuantity) {
                    $('#note-error-atleastoneproduct').removeClass('hidden');
                    return false; // Stop the submission process
                }

                // TODO check fields: firstname, lastname, address, zip, city, country, email


                this.submit();
            });
        },

    }
}();