var dvForms = function () {   
    var search_timeout = null;
    var write_timeout = null;
    
    var initFormElements = function(){
        console.log('init FORM');
        if(!$('body').hasClass('has-bodyevents')){
            $('body').addClass('has-bodyevents');
            $('body').on('keydown', '.dvgui-text', function(e) { 
                handleInput($(this));
            });
            $('body').on('blur', '.dvgui-text, .dvgui-select', function(e) { 
                handleInput($(this));
            });
            $('body').on('select', '.dvgui-select', function(e) { 
                handleInput($(this));
            });
            $('.dvgui-text, .dvgui-select').each(function(){
                handleInput($(this));
            });
            $('.dvgui-radio label').on('click').each(function(){
                handleInput($(this));
            });
            /// init for errors
            $('.dvgui-text, .dvgui-select, .dvgui-checkbox, .dvgui-radio').each(function(){
                handleErrors($(this))
            });
        }
    
        $('.add-datepicker').each(function(){
            if(!$(this).hasClass('has-datepicker')){
                $(this).addClass('has-datepicker');
                var that = $(this);
                var name = $(this).attr('data-dpname');
                var type = $(this).attr('data-type');
                var params = {
                    dateFormat: 'dd.mm.yy',
                    altField: '#' + name,
                    altFormat: 'yy-mm-dd',
                    /*changeMonth: true,
                    changeYear: true,*/
                    onSelect: function() {
                        handleInput($(that));
                        if($(that).hasClass('search-date')){
                            var form = $(that).parents('form');
                            var trg = form.attr('data-trg');
                            $('#' + trg + 'searchresult').html('<div class="loader"></div>');
                            window.clearTimeout(search_timeout);
                            search_timeout = window.setTimeout(submitSearch.bind(null,form), 800);
                        }

                    }
                }
                if(type == 'onlyfuture'){
                    params['minDate'] = new Date();
                }
                $(this).datepicker(params);
                $(this).on('change', function(){
                   if($(this).val() == ''){
                       $('#' + name).val('');
                   } 
                });
            }
        });
    }

    var handleInput = function(el) {
        if (el.val() != "") {
            el.addClass('edited');
        } else {
            el.removeClass('edited');
        }
    } 
    var handleErrors = function(el) {
        if (el.hasClass('dvForm_missed')) {
            el.parent('.form-group').addClass('has-error');
        } else if (el.find('input').hasClass('dvForm_missed')){
            el.parent('.form-group').addClass('has-error');
        }
    } 
    var initSearch = function(el) {
        $('.search-text').keyup(function(){
            var form = $(this).parents('form');
            var trg = form.attr('data-trg');
            $('#' + trg + 'searchresult').html('<div class="loader"></div>');
            window.clearTimeout(search_timeout);
            search_timeout = window.setTimeout(submitSearch.bind(null,form), 800);
        });
        $('.search-select').change(function(){
            var form = $(this).parents('form');
            var trg = form.attr('data-trg');
            $('#' + trg + 'searchresult').html('<div class="loader"></div>');
            window.clearTimeout(search_timeout);
            search_timeout = window.setTimeout(submitSearch.bind(null,form), 800);
        });
        $('.search-submit').click(function(){
            var form = $(this).parents('form');
            var trg = form.attr('data-trg');
            $('#' + trg + 'searchresult').html('<div class="loader"></div>');
            submitSearch(form);
        });
        $('.search-reset').click(function(){
            var form = $(this).parents('form');
            var trg = form.attr('data-trg');
            $('#' + trg + 'searchresult').html('<div class="loader"></div>');
            form.find('.search-text').val('').removeClass('edited');
            form.find('.search-select').val('').removeClass('edited');
            form.find('.search-date').val('').removeClass('edited').next().val('');
            submitSearch(form);
        });
        // if fields are not empty, submit form initially
        $('.search-text:visible, .search-select:visible, .search-date:visible').each(function(){
            if($(this).val() != ''){
                var form = $(this).parents('form');
                var trg = $(this).attr('data-trg');
                $('#' + trg + 'searchresult').html('<div class="loader"></div>');
                submitSearch(form);
                return false;
            }
        });
    } 
    
    var submitSearch = function(form) {
        var data = form.serialize();
        var action = form.attr('action');
        var trg = form.attr('data-trg');

        $.ajax({
            type: 'POST',
            url: action,
            data: data,
            
            success: function(content) {
                $('.loader').hide(0);
                var json_data = $.parseJSON(content);

                if(json_data['meta']['query']){
                    console.log('dvDebug: QUERY: ' + json_data['meta']['query']);
                }
                if(json_data['meta']['total']){
                    $('.total-items').html(json_data['meta']['total']);
                }
                if(typeof json_data['items'] !== 'undefined' && json_data['items'].length > 0){
                    $('#' + trg + 'searchresult').parent().addClass('show');
                    $('#' + trg + 'searchnoresult').removeClass('show');
                    $.each(json_data['items'], function(i, item) {
                        var row = dvf.template(json_data['template'], item);
                        $('#' + trg + 'searchresult').append(row);
                    });
                } else {
                    $('#' + trg + 'searchresult').parent().addClass('show');
                    $('#' + trg + 'searchnoresult').addClass('show');
                    $('#' + trg + 'searchresult').html('');
                }
               dvGui.iniAfterLoading();
               return false;
            }
        });
    }
    
    var initFormImages = function(){
        $('.delete-image').each(function(){
            if(!$(this).hasClass('has-delete')){
                $(this).addClass('has-delete');
                $(this).on('click', function(){
                    var imgrp = $(this).parent().parent().attr('data-imgrp');
                    var id = $(this).parent().attr('data-id');
                    var container = $('#fileupload-result-' + imgrp);
                    $.ajax({
                        type: 'POST',
                        url: '/action/imagedelete',
                        data: '&imgrp=' + imgrp + '&id=' + id,
                        success: function(content) {
                            showImageItems(container,content)
                        }
                    });
                });
            }
        });
        $('.comment-image').each(function(){
            if(!$(this).hasClass('has-comment')){
                $(this).addClass('has-comment');
                $(this).on('keyup', function(){
                    var imgrp = $(this).parent().parent().attr('data-imgrp');
                    var id = $(this).parent().attr('data-id');
                    var that = $(this);
                    $('.formcheck-icon').remove();
                    window.clearTimeout(write_timeout);
                    write_timeout = window.setTimeout(function(){
                        var val = $(that).val();
                        $.ajax({
                            type: 'POST',
                            url: '/action/imagecomment',
                            data: '&imgrp=' + imgrp + '&id=' + id + '&val=' + val,
                            success: function() {
                                console.log('OK');
                                window.clearTimeout(write_timeout);
                                $(that).parent().append('<svg class="formcheck-icon"><use xlink:href="/dist/gui.svg#check"></use></svg>');
                                /*
                                window.setTimeout(function(){
                                    $('.formcheck-icon').remove();
                                },2000);*/
                            }
                        });
                    }, 800);
                });
            }
        });

        $('.file-upload-result').each(function(){
            if(!$(this).hasClass('has-sortable')){
                $(this).addClass('has-sortable');
                var that = $(this);
                $(this).sortable({
                    handle: ".move-image",
                    stop: function (event, ui) {
                        var imgrp = $(this).attr('data-imgrp');
                        var id = $(this).attr('data-id');
                        var data = $(this).sortable('serialize');
                        $.ajax({
                            data: data + '&imgrp=' + imgrp + '&id=' + id,
                            type: 'POST',
                            url: '/action/imagesort',
                            success: function(content) {
                                showImageItems(that,content);
                            }
                        });
                    }
                });
            }
        });
    }

    
    var initFormDocuments = function(){
        $('.delete-document').each(function(){
            if(!$(this).hasClass('has-delete')){
                $(this).addClass('has-delete');
                $(this).on('click', function(){
                    var imgrp = $(this).parent().parent().attr('data-imgrp');
                    var id = $(this).parent().attr('data-id');
                    var container = $('#documentupload-result-' + imgrp);
                    $.ajax({
                        type: 'POST',
                        url: '/action/documentdelete',
                        data: '&imgrp=' + imgrp + '&id=' + id,
                        success: function(content) {
                            showDocumentItems(container,content)
                        }
                    });
                });
            }
        });
        $('.comment-document').each(function(){
            if(!$(this).hasClass('has-comment')){
                $(this).addClass('has-comment');
                $(this).on('keyup', function(){
                    var imgrp = $(this).parent().parent().attr('data-imgrp');
                    var id = $(this).parent().attr('data-id');
                    var that = $(this);
                    $('.formcheck-icon').remove();
                    window.clearTimeout(write_timeout);
                    write_timeout = window.setTimeout(function(){
                        var val = $(that).val();
                        $.ajax({
                            type: 'POST',
                            url: '/action/documentcomment',
                            data: '&imgrp=' + imgrp + '&id=' + id + '&val=' + val,
                            success: function() {
                                console.log('OK');
                                window.clearTimeout(write_timeout);
                                $(that).parent().append('<svg class="formcheck-icon"><use xlink:href="/dist/gui.svg#check"></use></svg>');
                            }
                        });
                    }, 800);
                });
            }
        });
        $('.document-upload-result').each(function(){
            if(!$(this).hasClass('has-sortable')){
                $(this).addClass('has-sortable');
                var that = $(this);
                $(this).sortable({
                    handle: ".move-document",
                    stop: function (event, ui) {
                        var imgrp = $(this).attr('data-imgrp');
                        var id = $(this).attr('data-id');
                        var data = $(this).sortable('serialize');
                        $.ajax({
                            data: data + '&imgrp=' + imgrp + '&id=' + id,
                            type: 'POST',
                            url: '/action/documentsort',
                            success: function(content) {
                                showDocumentItems(that,content);
                            }
                        });
                    }
                });
            }
        });
    }

    var showImageItems = function(container,content){
        var json_data = $.parseJSON(content);
        $(container).html('');
        $.each(json_data['items'], function(i, item) {
            var row = dvf_template(json_data['template'], item);
            $(container).append(row);
            initFormImages();
        });
        var instance = $(container).attr('id').replace('fileupload-result-','');
        countNum(instance);
    }

    var showDocumentItems = function(container,content){
        var json_data = $.parseJSON(content);
        $(container).html('');
        $.each(json_data['items'], function(i, item) {
            var row = dvf_template(json_data['template'], item);
            $(container).append(row);
            initFormDocuments();
        });
        var instance = $(container).attr('id').replace('documentupload-result-','');
        countNumDocuments(instance);
    }

    var initImageUploads = function(){
        console.log('INIT Fileupload');
        $('.imageupload').each(function(){
            var instance = $(this).attr('data-instance');

            $('#fileupload-button-' + instance).click(function(){
                $('#fileupload-' + instance).click();
            });
            
            $('#fileupload-' + instance).fileupload({
                add: function(e, data) {
                    $('#fileupload-notice-' + instance).fadeOut('fast');
                    var uploadErrors = [];
                    var acceptFileTypes = /^image\/(gif|jpe?g|png)$/i;
                    if(data.originalFiles[0]['type'].length && !acceptFileTypes.test(data.originalFiles[0]['type'])) {
                        uploadErrors.push(translatejs('Dieser Dateityp kann nicht hochgeladen werden.'));
                    }
                    if(uploadErrors.length > 0) {
                        $('#fileupload-notice-' + instance).html(uploadErrors.join("<br />"));
                        $('#fileupload-notice-' + instance).fadeIn('fast');
                    } else {
                        data.submit();
                    }
                },
                autoUpload: false,
                progressall: function (e, data) {
                    if(!$('#fileupload-progress-' + instance).is(':visible')){
                        $('#fileupload-progress-' + instance).fadeIn('fast');
                    }
                    var progress = parseInt(data.loaded / data.total * 100, 10);
                    $('#fileupload-progress-' + instance + ' div span').html(progress + '%');
                    $('#fileupload-progress-' + instance + ' div').css('width',progress + '%');
                },
                done: function (e, data) {
                    var returns = data.result;
                    $('#fileupload-result-' + instance).val(returns[0]);
                    $('#fileupload-progress-' + instance).fadeOut('slow');
                    if(instance == 'profile'){
                        var values = returns.split('#|#');
                        $('#profile_image').css('background-image','url(\'/data/img/' + values[0] + '/square_' + values[1] + '\')');
                    } else {
                        showImageItems($('#fileupload-result-' + instance),returns);
                    }
                }
            });
            countNum(instance);
        });
        initFormImages();


    }
    
    var initDocumentUploads = function(){
        console.log('INIT Documentupload');
        $('.documentupload').each(function(){
            var instance = $(this).attr('data-instance');

            $('#documentupload-button-' + instance).click(function(){
                $('#documentupload-' + instance).click();
            });
            
            $('#documentupload-' + instance).fileupload({
                add: function(e, data) {
                    $('#documentupload-notice-' + instance).fadeOut('fast');
                    var uploadErrors = [];
                    var acceptFileTypes = /^application\/(pdf)$/i;
                    if(data.originalFiles[0]['type'].length && !acceptFileTypes.test(data.originalFiles[0]['type'])) {
                        uploadErrors.push('Dieser Dateityp kann nicht hochgeladen werden.');
                    }
                    if(uploadErrors.length > 0) {
                        $('#documentupload-notice-' + instance).html(uploadErrors.join("<br />"));
                        $('#documentupload-notice-' + instance).fadeIn('fast');
                    } else {
                        data.submit();
                    }
                },
                autoUpload: false,
                progressall: function (e, data) {
                    if(!$('#documentupload-progress-' + instance).is(':visible')){
                        $('#documentupload-progress-' + instance).fadeIn('fast');
                    }
                    var progress = parseInt(data.loaded / data.total * 100, 10);
                    $('#documentupload-progress-' + instance + ' div span').html(progress + '%');
                    $('#documentupload-progress-' + instance + ' div').css('width',progress + '%');
                },
                done: function (e, data) {
                    var returns = data.result;
                    $('#documentupload-result-' + instance).val(returns[0]);
                    $('#documentupload-progress-' + instance).fadeOut('slow');
                    showDocumentItems($('#documentupload-result-' + instance),returns);
                }
            });
            countNumDocuments(instance);
        });
        initFormDocuments();
    }
    var countNum = function(instance){
        if($('#fileupload-result-' + instance + ' .image-item').length >= 10){
            $('#fileupload-notice-' + instance).html('Es können nicht mehr als 10 Fotos hochgeladen werden').show('fast');
        } else {
            $('#fileupload-notice-' + instance).html('').hide(0);
        }
    }    
    var countNumDocuments = function(instance){
        if($('#documentupload-result-' + instance + ' .image-item').length >= 10){
            $('#documentupload-notice-' + instance).html('Es können nicht mehr als 10 Fotos hochgeladen werden').show('fast');
        } else {
            $('#documentupload-notice-' + instance).html('').hide(0);
        }
    }    
    
    ////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
    //// public ////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
    return {
        init: function() {
            initFormElements();
            initImageUploads();
            initDocumentUploads();
        },
        initSearch: function() {
            initSearch()
        },
        submitSearch: function() {
            submitSearch()
        },
    }
}();
